import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import LoadingState from '../states/LoadingState';
import { IAttachment, IGoodsService, IOrderDetail, IOrderItem, IOrderItemGroupV2, IOrderItemStepHistory, IProductCategory, OrderItemStep, OrderPaymentStatus, deleteHistory, deleteOrder, getOrderDetails, getOrderItemStepName, postHistory, putHistory, putPayment } from '../services/OrderService';
import { showErrorModal } from '../components/MModal';
import { Button, Col, Descriptions, Divider, Drawer, Empty, Flex, Form, Input, Modal, ModalFuncProps, Radio, Row, Space, Spin, Table, Tag } from 'antd';
import { displayDate, displayDateTime, displayPrice } from '../utilities/FormatUtil';
import { ColumnsType } from 'antd/es/table';
import CommissionTag from '../components/CommisionTag';
import QuickTag from '../components/QuickTag';
import OrderItemProgress from '../components/OrderItemProgress';
import { add, parseISO } from 'date-fns';
import OrderItemStepTag from '../components/OrderItemStepTag';
import OrderPaymentStatusTag from '../components/OrderPaymentStatusTag';
import { getPaymentHistories, getPaymentActionTypeName, getAcquireStatusName, getBankName, PaymentActionType } from '../services/PaymentHistoryService';
import PaymentStatusTag from '../components/PaymentStatusTag';
import SettlementStatusTag from '../components/SettlementStatusTag';
import { IStampUrl, getStampDownloadUrl, getStampViewUrl } from '../services/StampService';
import { getFileDownloadUrl, IUrl } from '../services/AttachmentService';
import { safetyHtmlValue } from '../utilities/ValueUtil';

const OrderDetails: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();

    const [loadingState, setLoadingState] = useRecoilState(LoadingState);

    const [openItemDrawer, setOpenItemDrawer] = useState(false);
    const [openPaymentStatusDrawer, setOpenPaymentStatusDrawer] = useState(false);
    const [openEditStepHistoryDrawer, setOpenEditStepHistoryDrawer] = useState(false);
    const [selectedItemGroup, setSelectedItemGroup] = useState<IOrderItemGroupV2 | null>(null);
    const [selectedStepHistory, setSelectedStepHistory] = useState<IOrderItemStepHistory | null>(null);
    const [order, setOrder] = useState<IOrderDetail>();
    const [paymentHistories, setPaymentHistories] = useState<Array<any>>([]);
    const [stampViewUrl, setStampViewUrl] = useState<IStampUrl>();
    const [attachments, setAttachments] = useState<Array<IAttachment & IUrl>>([]);
    const navigate = useNavigate();

    const [orderPaymentForm] = Form.useForm();

    const loadOrder = useCallback(async () => {
        const result = await getOrderDetails(orderId!);
        if (result.isSuccess) {
            setOrder(result.dto!);

            if (result.dto!.stampId) {
                const stampResult = await getStampViewUrl(result.dto!.stampId!);
                if (stampResult.isSuccess) {
                    setStampViewUrl(stampResult.dto!);
                } else {
                    showErrorModal(stampResult.error);
                }
            }

            const files: Array<IAttachment & IUrl> = [];
            result.dto!.attachments?.map(async (a) => {
                const result = await getFileDownloadUrl(a.id, a.fileName);
                result.isSuccess && files.push({ ...a, ...result.dto! });
            });
            setAttachments(files);
        } else {
            showErrorModal(result.error);
        }
    }, [orderId]);

    const loadPaymentHistory = useCallback(async () => {
        const paymentHistories = await getPaymentHistories(0, 50, orderId!);
        if (paymentHistories.isSuccess) {
            setPaymentHistories(paymentHistories.dto!.items);
        } else {
            showErrorModal(paymentHistories.error);
        }
    }, [orderId]);

    const initLoad = useCallback(async () => {
        setLoadingState(true);
        await loadOrder();
        await loadPaymentHistory();
        setLoadingState(false);
    }, [loadOrder, loadPaymentHistory, setLoadingState]);

    const [modal, contextHolder] = Modal.useModal();

    const handleEditStepHistory = (stepHistory: IOrderItemStepHistory) => {
        setSelectedStepHistory(stepHistory);
        setOpenEditStepHistoryDrawer(true);
    };

    const handleDeleteStepHistory = (itemIds: Array<string>, stepHistory: IOrderItemStepHistory) => {
        modal.confirm({
            title: "아래 진행현황을 삭제할까요?",
            content: (
                <Flex gap="small" vertical={true}>
                    <strong>{getOrderItemStepName(stepHistory.step)}</strong>
                    <div>{stepHistory.description}</div>
                </Flex>
            ),
            onOk: async () => {
                setLoadingState(true);
                const result = await deleteHistory(order!.id!, itemIds, stepHistory.ids);
                if (result.isSuccess) {
                    await loadOrder();
                } else {
                    showErrorModal(result.error);
                }
                setLoadingState(false);

            },
            okText: "삭제",
            cancelText: "취소",
        });
    };

    useEffect(() => {
        initLoad();
    }, [initLoad]);

    useEffect(() => {
        if (selectedItemGroup) {
            setOpenItemDrawer(true);
        } else {
            setOpenItemDrawer(false);
        }
    }, [selectedItemGroup]);

    useEffect(() => {
        if (selectedStepHistory) {
            setOpenEditStepHistoryDrawer(true);
        } else {
            setOpenEditStepHistoryDrawer(false);
        }
    }, [selectedStepHistory]);

    const handleClickStamp = async (isOriginal: boolean) => {
        const result = await getStampDownloadUrl(order!.stampId!, `${order!.publicNumber}위임장도장.png`);
        if (result.isSuccess) {
            window.location.href = isOriginal ? result.dto!.original : result.dto!.backgroundRemoval;
        } else {
            showErrorModal(result.error);
        }
    }

    const itemGroupColumns: ColumnsType<IOrderItem> = [
        {
            title: "상품류",
            render: (_, record) => <>{`${record.classificationCode}류`}</>
        },
        {
            title: "출원방식",
            render: (_, record) => <>{<CommissionTag type={record.commissionType}></CommissionTag>} {displayPrice(record.commission)}</>
        },
        {
            title: "심사 기간 단축",
            dataIndex: "isQuick",
            render: (_, record) => <><QuickTag isQuick={_} /> {record.quickCommission > 0 && displayPrice(record.quickCommission)}</>
        },
        {
            title: "관납료",
            render: (_, record) => <>{displayPrice(record.officialFee)}</>
        },
        {
            title: "특허사무소 수수료",
            render: (_, record) => <>{displayPrice(record.officeFee)}</>
        },
        {
            title: "합",
            align: "right",
            render: (_, record) => <>{displayPrice(record.amount)}</>
        }
    ];

    const paymentHistoryColumns: ColumnsType<any> = [
        {
            title: "구분",
            dataIndex: "action",
            width: 120,
            render: (action) => <>{getPaymentActionTypeName(action)}</>
        },
        {
            title: "결과",
            render: (_, record) => {
                if (record.action === PaymentActionType.Manual) {
                    return (
                        <Descriptions column={1} size="small" bordered={false}>
                            <Descriptions.Item label="코멘트"><div dangerouslySetInnerHTML={{ __html: safetyHtmlValue(record.comment) }} /></Descriptions.Item>
                        </Descriptions>
                    );
                }
                else if (record.success) {
                    return (
                        <Descriptions column={1} size="small" bordered={false}>
                            <Descriptions.Item label="상태코드"><PaymentStatusTag status={record.success.status} /></Descriptions.Item>
                            <Descriptions.Item label="결제수단">{record.success.method}</Descriptions.Item>
                            <Descriptions.Item label="요청시간">{displayDateTime(record.success.requestedAt)}</Descriptions.Item>
                            <Descriptions.Item label="승인시간">{record.success.approvedAt && displayDateTime(record.success.approvedAt)}</Descriptions.Item>

                            {record.success.card && <Descriptions.Item label="승인번호">{record.success.card.approveNo}</Descriptions.Item>}
                            {record.success.card && <Descriptions.Item label="카드번호">{record.success.card.number}</Descriptions.Item>}
                            {record.success.card && <Descriptions.Item label="카드종류">{record.success.card.cardType}</Descriptions.Item>}
                            {record.success.card && <Descriptions.Item label="카드소유자">{record.success.card.ownerType}</Descriptions.Item>}
                            {record.success.card &&
                                <Descriptions.Item label="할부">
                                    {record.success.card.installmentPlanMonths === 0 ? "일시불" : `${record.success.card.installmentPlanMonths}개월`}
                                    {record.success.card.isInterestFree ? "(무이자)" : ""}
                                </Descriptions.Item>}
                            {record.success.card && <Descriptions.Item label="매입상태">{getAcquireStatusName(record.success.card.acquireStatus)}</Descriptions.Item>}

                            {record.success.virtualAccount && <Descriptions.Item label="계좌타입">{record.success.virtualAccount.accountType}</Descriptions.Item>}
                            {record.success.virtualAccount && <Descriptions.Item label="계좌번호">{getBankName(record.success.virtualAccount.bankCode)} {record.success.virtualAccount.accountNumber} {record.success.virtualAccount.expired && <Tag color="error">계좌 만료</Tag>}</Descriptions.Item>}
                            {record.success.virtualAccount && <Descriptions.Item label="고객이름">{record.success.virtualAccount.customerName}</Descriptions.Item>}
                            {record.success.virtualAccount && <Descriptions.Item label="입금기한">{displayDateTime(record.success.virtualAccount.dueDate)}</Descriptions.Item>}
                            {record.success.virtualAccount && <Descriptions.Item label="정산상태"><SettlementStatusTag status={record.success.virtualAccount.settlementStatus} /></Descriptions.Item>}
                            {record.success.virtualAccount && <Descriptions.Item label="환불계좌">{record.success.virtualAccount.refundReceiveAccount && getBankName(record.success.virtualAccount.refundReceiveAccount.bankCode)} {record.success.virtualAccount.refundReceiveAccount?.accountNumber} {record.success.virtualAccount.refundReceiveAccount?.holderName}</Descriptions.Item>}

                            {record.success.easyPay && <Descriptions.Item label="간편결제사">{record.success.easyPay.provider}</Descriptions.Item>}
                            {record.success.easyPay && <Descriptions.Item label="계좌 또는 현금성포인트">{displayPrice(record.success.easyPay.amount)}</Descriptions.Item>}
                            {record.success.easyPay && <Descriptions.Item label="적립포인트 또는 쿠폰">{displayPrice(record.success.easyPay.discountAmount)}</Descriptions.Item>}

                            {record.success.mobilePhone && <Descriptions.Item label="휴대폰 번호">{record.success.mobilePhone.customerMobilePhone}</Descriptions.Item>}
                            {record.success.mobilePhone && <Descriptions.Item label="정산상태"><SettlementStatusTag status={record.success.mobilePhone.settlementStatus} /></Descriptions.Item>}
                            {record.success.mobilePhone && <Descriptions.Item label="영수증"><a href={record.success.mobilePhone.receiptUrl} target="_blank" rel="noreferrer">영수증 보기</a></Descriptions.Item>}

                            {record.success.receipt && <Descriptions.Item label="영수증"><a href={record.success.receipt.url} target="_blank" rel="noreferrer">영수증 보기</a></Descriptions.Item>}

                            {record.success.failure && <Descriptions.Item label="에러코드">{record.success.failure.code}</Descriptions.Item>}
                            {record.success.failure && <Descriptions.Item label="메시지">{record.success.failure.message}</Descriptions.Item>}
                        </Descriptions>
                    );
                } else if (record.depositCallback) {
                    return (
                        <Descriptions column={1} size="small" bordered={false}>
                            <Descriptions.Item label="상태코드"><PaymentStatusTag status={record.depositCallback.status} /></Descriptions.Item>
                            <Descriptions.Item label="거래키">{record.depositCallback.transactionKey}</Descriptions.Item>
                            <Descriptions.Item label="날짜">{displayDateTime(record.depositCallback.createdAt)}</Descriptions.Item>
                        </Descriptions>
                    );
                } else if (record.error) {
                    return (
                        <Descriptions column={1} size="small" bordered={false}>
                            <Descriptions.Item label="에러코드">{record.error.code}</Descriptions.Item>
                            <Descriptions.Item label="메시지">{record.error.message}</Descriptions.Item>
                        </Descriptions>
                    );
                } else {
                    return <>{`${record.status}`}</>;
                }
            }
        },
        {
            title: "날짜",
            dataIndex: "utcCreatedDate",
            width: 200,
            render: (date) => <>{displayDateTime(date)}</>
        }
    ];

    const productCategoriesColumns: ColumnsType<IProductCategory> = [
        {
            title: "분류1",
            render: (_, record) => record.c1
        },
        {
            title: "분류2",
            render: (_, record) => record.c2
        },
        {
            title: "분류3",
            render: (_, record) => record.c3
        },
        {
            title: "분류4",
            render: (_, record) => record.c4
        }
    ];

    const goodsServicesColumns: ColumnsType<IGoodsService> = [
        {
            title: "상품류",
            render: (_, record) => record.classificationCode
        },
        {
            title: "명칭",
            render: (_, record) => <>{`${record.koreanText}`}<br />{`${record.englishText}`}</>
        },
        {
            title: "유사군",
            render: (_, record) => record.similarCodes
        },
    ];

    const deleteModalProps: ModalFuncProps = {
        title: "출원 요청 삭제",
        content: "출원 요청을 삭제하시겠습니까?",
        onOk: async () => {
            setLoadingState(true);
            const result = await deleteOrder(orderId!);
            if (result.isSuccess) {
                navigate("/order");
            } else {
                showErrorModal(result.error);
            }
            setLoadingState(false);
        },
    }

    return (
        order ?
            <Row gutter={[32, 32]}>
                <Col span={24}>
                    <Descriptions title={"기본정보"} bordered={true} column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        extra={<Button type="primary" danger onClick={() => Modal.confirm(deleteModalProps)}>삭제</Button>}>
                        <Descriptions.Item label="주문번호">{order?.publicNumber}</Descriptions.Item>
                        <Descriptions.Item label="키">{order?.id}</Descriptions.Item>
                        <Descriptions.Item label="계정">{order?.fullName} ({order?.userName})</Descriptions.Item>
                        <Descriptions.Item label="등록일">{displayDateTime(order?.utcCreatedDate)}</Descriptions.Item>
                        <Descriptions.Item label="상표명(한글)">{order?.koreanTrademarkName}</Descriptions.Item>
                        <Descriptions.Item label="상표명(영문)">{order?.englishTrademarkName}</Descriptions.Item>
                        <Descriptions.Item label="상표설명" span={2}><div dangerouslySetInnerHTML={{ __html: safetyHtmlValue(order?.description ?? "") }} /></Descriptions.Item>
                        <Descriptions.Item label="위임장 도장" span={2}>
                            {stampViewUrl ?
                                <Space size="large">
                                    <img src={stampViewUrl.original} alt="" className='stamp' onClick={async () => await handleClickStamp(true)} />
                                    <img src={stampViewUrl.backgroundRemoval} alt="" className="stamp br" onClick={async () => await handleClickStamp(false)} />
                                </Space> :
                                <>도장 없음</>}
                        </Descriptions.Item>
                        <Descriptions.Item label="상표/로고/캐릭터(도면)" span={2}>
                            <Space size="large">
                                {attachments.map((a, index) => {
                                    const preview = a.contentType.startsWith("image") ? (<img src={a.url} alt={a.fileName} title={a.fileName} />) : (<span className='empty'></span>);
                                    return (<a key={index} href={a.url} className='attachment'>{preview}<p>{a.fileName}</p></a>);
                                })}
                                {attachments.length === 0 && "상표/로고/캐릭터(도면) 없음"}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="추천인 코드" span={2}>{order?.recommendation}</Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={12}>
                    <Descriptions title="출원인" bordered={true} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                        <Descriptions.Item label="성명(한글)">{order?.applicant.koreanFullName}</Descriptions.Item>
                        <Descriptions.Item label="성명(영문)">{order?.applicant.englishFullName}</Descriptions.Item>
                        <Descriptions.Item label="특허 고객 번호">{order?.applicant.applicantCode}</Descriptions.Item>
                        <Descriptions.Item label="이메일">{order?.applicant.email}</Descriptions.Item>
                        <Descriptions.Item label="휴대전화">{order?.applicant.phoneNumber}</Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={12}>
                    <Descriptions title="담당자" bordered={true} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                        <Descriptions.Item label="성명">{order?.operator.fullName}</Descriptions.Item>
                        <Descriptions.Item label="이메일">{order?.operator.email}</Descriptions.Item>
                        <Descriptions.Item label="휴대전화">{order?.operator.phoneNumber}</Descriptions.Item>
                        <Descriptions.Item label="유선전화">{order?.operator.telephoneNumber}</Descriptions.Item>
                    </Descriptions>
                </Col>

                {order?.productCategories &&
                    <Col span={24}>
                        <Descriptions title="분류"></Descriptions>
                        <Table
                            columns={productCategoriesColumns} dataSource={order?.productCategories} rowKey={(r) => `${r.c1}${r.c2}${r.c3}${r.c4}`} pagination={false}
                        />
                    </Col>
                }

                {order?.goodsServices &&
                    <Col span={24}>
                        <Descriptions title="지정상품"></Descriptions>
                        <Table
                            columns={goodsServicesColumns} dataSource={order?.goodsServices} rowKey={(r) => r.englishText} pagination={false}
                        />
                    </Col>}

                {order?.groups.map((itemGroup, index) => {
                    const 특허청심사예정일 = add(parseISO(order?.utcCreatedDate), { months: itemGroup.isQuick ? 4 : 12 }).toISOString();
                    const 최종등록예상일from = add(parseISO(order?.utcCreatedDate), { months: itemGroup.isQuick ? 6 : 14 }).toISOString();
                    const 최종등록예상일to = add(parseISO(order?.utcCreatedDate), { months: itemGroup.isQuick ? 12 : 20 }).toISOString();
                    const title = itemGroup.classifications.map((i) => `${i.code}류`).join(", ");

                    return (
                        <Col span={24} key={itemGroup.ids[0]}>
                            <Descriptions bordered={true} layout="vertical" title={title} column={{ xxl: 5, xl: 5, lg: 5, md: 5, sm: 5, xs: 5 }}>
                                <Descriptions.Item label="출원방식"><CommissionTag type={itemGroup.commissionType} /></Descriptions.Item>
                                <Descriptions.Item label="심사 기간 단축"><QuickTag isQuick={itemGroup.isQuick} /></Descriptions.Item>
                                <Descriptions.Item label="상표 출원 예정일">{displayDate(order?.utcCreatedDate)}</Descriptions.Item>
                                <Descriptions.Item label="1차 심사 결과 통지 예정일">{displayDate(특허청심사예정일)}</Descriptions.Item>
                                <Descriptions.Item label="최종 결제 예상일">{displayDate(최종등록예상일from)} ~ {displayDate(최종등록예상일to)}</Descriptions.Item>
                                <Descriptions.Item label="진행현황">
                                    <OrderItemProgress item={itemGroup}
                                        onClickEditButton={handleEditStepHistory}
                                        onClickDeleteButton={handleDeleteStepHistory} />
                                    <Divider />
                                    <div style={{ textAlign: "right" }}>
                                        <Button type="primary" onClick={() => setSelectedItemGroup(itemGroup)}>진행현황 업데이트</Button>
                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    );
                })}

                <Col span={24}>
                    <Descriptions title="결제 상세 내역"></Descriptions>
                    <Table
                        columns={itemGroupColumns} dataSource={order?.itemGroups.flatMap((g) => g.items)} rowKey={(r) => r.id} pagination={false}
                        footer={() => <div style={{ textAlign: "right" }}><OrderPaymentStatusTag status={order?.paymentStatus} /> 총 {displayPrice(order?.totalAmountWIthVAT)} (VAT포함)</div>}
                    />
                </Col>

                <Col span={24}>
                    <Descriptions title="결제 로그"></Descriptions>
                    <div style={{ textAlign: "right", paddingBottom: 24 }}>
                        <Button type="primary" onClick={() => setOpenPaymentStatusDrawer(true)}>결제 완료 처리</Button>
                    </div>
                    <Table columns={paymentHistoryColumns} dataSource={paymentHistories} rowKey={(r) => r.id} pagination={false} />
                </Col>

                <Drawer open={openItemDrawer} width={500} maskClosable={false} keyboard={false} title="진행현황 업데이트"
                    onClose={() => setSelectedItemGroup(null)} >
                    {selectedItemGroup &&
                        <Spin spinning={loadingState} style={{ maxHeight: "100vh" }}>
                            <Form layout="vertical"
                                initialValues={{ step: selectedItemGroup.currentStep === OrderItemStep.Pending ? OrderItemStep.Request : selectedItemGroup.currentStep }}
                                onFinish={async (values: any) => {
                                    setLoadingState(true);
                                    const result = await postHistory(order?.id, selectedItemGroup.ids, values.step, values.description);
                                    if (result.isSuccess) {
                                        setSelectedItemGroup(null);
                                        await loadOrder();
                                        await loadPaymentHistory();
                                    } else {
                                        showErrorModal(result.error);
                                    }
                                    setLoadingState(false);
                                }}>
                                <Form.Item label="상품류">
                                    {selectedItemGroup.classifications.map((i) => `${i.code}류`).join(", ")}
                                </Form.Item>
                                <Form.Item label="현재단계">
                                    <OrderItemStepTag step={selectedItemGroup.currentStep} />
                                </Form.Item>
                                <Form.Item label="단계선택" name="step">
                                    <Radio.Group>
                                        <Radio.Button value={OrderItemStep.Request}>{getOrderItemStepName(OrderItemStep.Request)}</Radio.Button>
                                        <Radio.Button value={OrderItemStep.Procedure}>{getOrderItemStepName(OrderItemStep.Procedure)}</Radio.Button>
                                        <Radio.Button value={OrderItemStep.Screening}>{getOrderItemStepName(OrderItemStep.Screening)}</Radio.Button>
                                        <Radio.Button value={OrderItemStep.Report}>{getOrderItemStepName(OrderItemStep.Report)}</Radio.Button>
                                        <Radio.Button value={OrderItemStep.Complete}>{getOrderItemStepName(OrderItemStep.Complete)}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="설명" rules={[{ required: true }]} required={true} name="description">
                                    <Input.TextArea rows={4} placeholder="진행상황을 설명해주세요." />
                                </Form.Item>
                                <Form.Item style={{ textAlign: "center" }}>
                                    <Button type="primary" htmlType="submit">확인</Button>
                                </Form.Item>
                            </Form>
                        </Spin>}
                </Drawer>

                <Drawer open={openPaymentStatusDrawer} width={500} maskClosable={false} keyboard={false} title="결제 완료 처리"
                    onClose={() => {
                        orderPaymentForm.resetFields(); setOpenPaymentStatusDrawer(false);
                    }}>
                    <Spin spinning={loadingState} style={{ maxHeight: "100vh" }}>
                        <Form layout="vertical"
                            form={orderPaymentForm}
                            initialValues={{ comment: "" }}
                            onFinish={async (values: any) => {
                                setLoadingState(true);
                                const result = await putPayment(order?.id, OrderPaymentStatus.Done, values.comment);
                                if (result.isSuccess) {
                                    orderPaymentForm.resetFields();
                                    setOpenPaymentStatusDrawer(false);
                                    await loadOrder();
                                    await loadPaymentHistory();
                                } else {
                                    showErrorModal(result.error);
                                }
                                setLoadingState(false);
                            }}>
                            <Form.Item label="메모" name="comment" rules={[{ required: true }]} required={true}>
                                <Input.TextArea rows={4} maxLength={2000} placeholder="메모를 남겨주세요." />
                            </Form.Item>
                            <Form.Item style={{ textAlign: "center" }}>
                                <Button type="primary" htmlType="submit">확인</Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Drawer>

                <Drawer open={openEditStepHistoryDrawer} width={500} maskClosable={false} keyboard={false} title="진행현황 수정"
                    onClose={() => {
                        setSelectedStepHistory(null);
                        setOpenEditStepHistoryDrawer(false);
                    }}>
                    {selectedStepHistory &&
                        <Spin spinning={loadingState} style={{ maxHeight: "100vh" }}>
                            <Form layout="vertical"
                                initialValues={{ description: selectedStepHistory.description }}
                                onFinish={async (values: any) => {
                                    setLoadingState(true);
                                    const result = await putHistory(order?.id, selectedStepHistory.ids, values.description);
                                    if (result.isSuccess) {
                                        setSelectedStepHistory(null);
                                        await loadOrder();
                                        await loadPaymentHistory();
                                    } else {
                                        showErrorModal(result.error);
                                    }
                                    setLoadingState(false);
                                }}>
                                <Form.Item label="단계">
                                    <OrderItemStepTag step={selectedStepHistory.step} />
                                </Form.Item>
                                <Form.Item label="설명" name="description">
                                    <Input.TextArea rows={4} placeholder="진행상황을 설명해주세요." />
                                </Form.Item>
                                <Form.Item style={{ textAlign: "center" }}>
                                    <Button type="primary" htmlType="submit">확인</Button>
                                </Form.Item>
                            </Form>
                        </Spin>}
                </Drawer>

                {contextHolder}
            </Row>
            :
            <Empty />
    );
}

export default OrderDetails;